import React, { useState, useEffect } from "react"

import Layout from "../components/Layout"

//Icon import
import facebook from "../assets/icons/facebook-black.svg"
import linkedin from "../assets/icons/linkedin-black.svg"
import twitter from "../assets/icons/twitter-black.svg"

const GoodByeManualReportingTR = () => {
  const [url, setUrl] = useState("")
  const hashtags = "Peny,ExpenseManagement,CompanyCards,ExpenseFraud"
  const via = "usemuni"
  /* 
    Acilacak olan browser sekmesinin boyutu ve hangi toollarin gosterileceginin
    belirlenmesi
  */
  const params = "menubar=no,toolbar=no,status=no,width=570,height=570"

  const shareWithFunction = url => {
    window.open(url, "NewWindow", params)
  }

  useEffect(() => {
    if (typeof window !== "undefined") setUrl(window.location)
  })

  return (
    <Layout>
      <div id="blog-details">
        <div id="header">
          <div id="header-details-container">
            <span id="header-details-container-date">Eylül 16, 2021</span>
            <span id="header-details-container-title">
              Fiziksel raporlara elveda!
            </span>
            <span id="header-details-container-author">
              by <span>Peny</span>
            </span>
          </div>
        </div>

        <div id="blog-body">
          <div id="blog-image" className="body_section">
            <img
              alt="blog container"
              src="https://website-blog-pictures.s3.eu-central-1.amazonaws.com/1631782599938.png"
            />
          </div>
          <span className="body_section">
            Teknoloji sağlamış olduğu kolaylık ve verimlilik sayesinde her geçen
            gün hayatımızı iyileştirmeye devam etmektedir. Ancak tüm bu
            teknolojik gelişmelere rağmen şirketlerin büyük bir çoğunluğu hâlâ
            masraf raporlama ve yönetimi için geleneksel raporlama yöntemleri
            kullanmaktadır.
          </span>
          <div className="body_image_wrap_right">
            <img
              alt="blog container"
              src="https://website-blog-pictures.s3.eu-central-1.amazonaws.com/1631784388183.png"
            />
          </div>

          <span className="body_section">
            Bu durumdan dolayı her sene çalışanların zaman ve enerjileri boşa
            harcanmaktadır. Üstüne üstlük geleneksel kontrol mekanizmaları
            yetersiz kaldığı için şirketler ciddi miktarda masraf sahteciliğine
            maruz kalmaktadır. Bu durum gittikçe kötüleşmektedir. 2020 yılında
            ABD’de yapılan bir ankete göre son üç yılda uygunsuz masrafların
            sayısında ciddi oranda bir artış olduğunu gözlenmiştir.
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.netsuite.com/portal/business-benchmark-brainyard/industries/articles/cfo-central/expense-report.shtml"
            >
              [1]
            </a>
          </span>
          <span className="body_section">
            Önlenemeyen masraf sahteciliği şirketlere ciddi zararlar
            vermektedir. 2016 yılında yapılan bir araştırmada, masraf
            sahteciliğinin ABD’deki şirketlere her yılda 2,8 milyar dolar zarara
            yol açtığı tespit edilmiştir.
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.businesswire.com/news/home/20160329005218/en/Expense-Fraud-Costs-U.S.-Employers-2.8-Billion-per-Year-Shows-Chrome-River-Survey"
            >
              [2]
            </a>{" "}
            Bu miktarın 2020’de Türkiye’deki şirketler için ₺350 - ₺500 milyon
            arasında olduğunu tahmin ediyoruz.
          </span>
          <span className="body_section">
            Masrafları elle raporlamanın ve geleneksel kanallarla yönetmenin
            şirketinizi zora soktuğunu gördük. Ancak bu durumun yarattığı
            zararların bununla sınırlı kaldığını düşünüyorsanız yanılıyorsunuz!
            Hadi bunu basit bir soru ile test edelim. Şirketteki çalışanlarınıza
            işteki en sıkıcı günlerini sorarsanız size ne cevap verirler?
          </span>
          <div className="body_image">
            <img
              alt="blog container"
              src="https://website-blog-pictures.s3.eu-central-1.amazonaws.com/1631783437311.png"
            />
          </div>
          <span className="body_section">
            Verecekleri cevabı tahmin etmek çok da zor olmasa gerek... Evet
            çalışanlarınızın finans departmanınızla yüzleştiği o kara günden
            bahsediyoruz. Ay boyunca yapmış oldukları harcamaları onaylatmak
            için yöneticilerinin peşinden koştukları o gün...
          </span>
          <span className="body_section">
            Masraflar için fiş ve fatura toplamanın ne kadar zor olduğunu
            hepimiz biliyoruz. Masrafların yapıldığı gibi raporlanmadığı için
            biriken bu belge seliyle her ay mücadele etmenin ne kadar zor
            olduğunu da biliyoruz. En kötüsü böyle yoğun ve stresli bir dönemde
            yapılan masraf raporlamalarının yanlış raporlamalara ve şirketinize
            ciddi zararlara yol açması. Bu dönemin yoğunluğunda gözden kaçan
            hatalı ve sahte masraflar ise işin tuzu biberi oluyor.
          </span>
          <span className="body_section">
            Sonuç olarak ne çalışanlara ne yöneticilere ne de şirkete faydası
            olan bu süreç kaçınılmaz bir son olarak görülüyor.
          </span>
          <span className="body_section">
            Ama bu durum kaçınılmaz değil! Akıllı masraf yönetim çözümlerimiz
            sayesinde masraf raporlama ve yönetme süreçlerini stresten uzak,
            basit ve eğlenceli bir hale dönüştürüyoruz.
          </span>
          <span className="body_section">
            Çalışanlarınızın raporlama esnasında tek yapmaları gereken fiş veya
            faturalarının fotoğrafını çekerek uygulamamıza yüklemek.
          </span>
          <div className="body_image_wrap_right">
            <img
              alt="blog container"
              src="https://website-blog-pictures.s3.eu-central-1.amazonaws.com/1631783789872.png"
            />
          </div>
          <span className="body_section">
            Sonrasında bu masraflar sizin belirlediğiniz onay akışlarına girer
            ve sorumlu yönetici anında bilgilendirilir. Çalışan masrafı sizin
            belirlediğiniz harcama zamanı, miktarı ve kategorisine göre kontrol
            edilerek limit aşımında size bilgi verilir. Böylelikle istenmeyen
            veya beklenmeyen masraflardan kaçınırsınız. Gelişmiş sahtecilik
            önleme sistemimiz sayesinde belgeleriyle uyuşmayan masraflardan da
            anında haberdar olursunuz.
          </span>
          <span className="body_section">
            Şirket yöneticileri ise çalışan masrafları ile ilgili bütün
            detayları kullanıcı dostu platformumuz üzerinden kolayca takip edip
            bu önemli gider kalemi üzerinde tam kontrol sahibi olacak.
            Böylelikle çalışanlarınız zamanlarını ve enerjilerini daha verimli
            kullanacak ve siz sorunsuz masraf yönetiminin keyfini
            çıkartacaksınız!
          </span>

          <div className="body_section_references">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.netsuite.com/portal/business-benchmark-brainyard/industries/articles/cfo-central/expense-report.shtml"
            >
              [1]
            </a>
            O'Brien, Megan. “The Future of Expense Reports: Spend Less Time and
            Effort on Reimbursement.” NetSuite. Accessed June 13, 2021.
          </div>
          <div className="body_section_references">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.businesswire.com/news/home/20160329005218/en/Expense-Fraud-Costs-U.S.-Employers-2.8-Billion-per-Year-Shows-Chrome-River-Survey"
            >
              [2]
            </a>{" "}
            Wheatcroft, Tim. “Expense Fraud COSTS U.S. EMPLOYERS $2.8 Billion
            per Year, Shows CHROME River Survey.” Expense Fraud Costs U.S.
            Employers $2.8 Billion per Year, Shows Chrome River Survey |
            Business Wire, March 29, 2016.
          </div>
        </div>

        <div id="tags-container">
          <span id="tags-container-title">Tags:</span>
          <div id="tag-container">
            <div className="tag_button">
              <span>Peny</span>
            </div>
            <div className="tag_button">
              <span>Masraflar</span>
            </div>
            <div className="tag_button">
              <span>Masraf Yönetimi</span>
            </div>
          </div>
        </div>

        <hr />

        <div id="social-container">
          <span>Share:</span>
          <div id="buttons-container">
            <div
              className="social_button"
              onClick={() => {
                const shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${url}`
                shareWithFunction(shareUrl)
              }}
            >
              <img src={linkedin} alt="Linkedin" />
            </div>
            <div
              className="social_button"
              onClick={() => {
                const shareUrl = `http://www.facebook.com/sharer/sharer.phpu=${url}`
                shareWithFunction(shareUrl)
              }}
            >
              <img src={facebook} alt="Facebook" />
            </div>
            <div
              className="social_button"
              onClick={() => {
                const shareUrl = `https://twitter.com/intent/tweet?url=${url}&text=&via=${via}&hashtags=${hashtags}`
                shareWithFunction(shareUrl)
              }}
            >
              <img src={twitter} alt="Twitter" />
            </div>
            {/* <div className="social_button">
            <img src={instagram} alt="Instagram" />
          </div> */}
          </div>
        </div>

        <div id="about-author-wrapper">
          <div id="about-author-card">
            <div id="about-author-card-top">
              <div id="about-author-card-top-avatar">
                <img
                  alt="author avatar"
                  src="https://media-exp1.licdn.com/dms/image/C4E0BAQGIWKhm4dCqIA/company-logo_200_200/0/1609746202791?e=1656547200&v=beta&t=1T2uU6-cLPmvNzB9pNn0-sC7U1H3-fmYUPsG-BUeAmo"
                />
              </div>
              <div id="about-author-card-top-info">
                <span>Peny</span>
              </div>
            </div>
            <div id="about-author-card-middle">About Author</div>

            <span id="about-author-card-bottom">
              Peny is a fintech start-up working to revolutionize the way for
              employees to make business expenditures and companies to manage
              their expenses. We are marrying smart company cards with advanced
              expense management software so that expenses become a breeze for
              companies that suffer millions of dollars in expense management
              costs every year.
            </span>
          </div>
        </div>

        <div id="leave-comment"></div>
      </div>
    </Layout>
  )
}

export default GoodByeManualReportingTR
